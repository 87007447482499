.border-right-unset {
  border-right: unset !important;
}

.selected {
  opacity: 1 !important;
  font-weight: 600 !important;
  font-size: 1.1rem !important;
}

.main-content .navbar-top {
  position: unset !important;
  top: unset !important;
  left: unset !important;
}

.my-nav-link {
  opacity: 0.9;
}

.admin-nav-item {
  border-right: 1px solid #a8a8a8;
}

.my-navbar .dropdown-menu {
  background: #dadad9 !important;
  color: black !important;
  border: 1px solid #fb6340;
}

@media (min-width: 768px) {
  .navbar .dropdown-menu-right:before {
    right: 20px;
    left: auto;
    visibility: hidden;
  }

  .navbar {
    .dropdown-item {
      display: flex !important;
      align-items: center !important;
    }
  }
}

@media (max-width: 767.98px) {
  .navbar-nav .nav-link {
    // padding: 0.625rem 0;
    color: white !important;
  }
}

@media (max-width: 560px) {
  .main-content .navbar-top {
    padding: 0.5rem 0.25rem !important;
  }

  #my-navbar {
    position: sticky;
    top: 100vh;
    bottom: 0px;
    left: 0px;
    background: #dadad9 !important;
    color: #36382e !important;
    width: 100% !important;
    flex-grow: 1;
    z-index: 999;

    .nav-item {
      flex-grow: 1 !important;
      border-right: 1px solid #a8a8a8;
    }

    .my-nav-link {
      opacity: 0.8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0 !important;
      font-size: 0.8rem;
      font-weight: 600;
      color: black !important;

      svg {
        margin-right: unset !important;
        margin-bottom: 2px !important;
      }
    }

    .selected {
      background: #ede6e3 !important;
      font-size: 0.9rem !important;
      font-weight: 600 !important;
      box-shadow: 1px 1px 1px 1px #b2adab inset;
    }

    .my-nav-link:hover,
    .my-nav-link:focus {
      color: black !important;
    }
  }

  #my-navbar .nav-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px !important;
    padding: 0;
    color: #36382e !important;
    font-size: 0.8rem;

    svg {
      margin-right: unset !important;
      margin-bottom: 4px;
    }

    .fa-chevron-down {
      transform: rotate(180deg);
    }
  }
}

body {
  position: relative;
}