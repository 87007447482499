.donation-card {
  width: 100%;
  margin: auto;
}
.donation-header {
  background: rgb(6, 100, 143) !important;
//   background:rgb(165, 40, 31) !important;
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 900px) {
  .donation-card {
    width: 60%;
  }
}
