#react-select-2-input {
  opacity: 1 !important;
}

input[id^='react-select-'] {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #525f7f !important;
  opacity: 1 !important;
}
