html,
body,
#root,
#root > div {
  height: 100%;
  background: #fbf7e9 !important;
}

.body-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

@media (max-width: 650px) {
  .body-container {
    margin-bottom: 10vh;
  }
}

.main-content {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
