body {
  overflow: hidden;
  background: transparent
    url('http://bemat-admin.cerocreativo.cl/v1.0.1/dark/img/bgs/bg3.png') left
    40%;
  background-size: cover;
  font-size: 15px;
  margin: 0;
  padding: 0;
}
.layout-align-center-vertical {
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  max-height: 100%;
}
.horizontal-align {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.width-7,
.size-7 {
  width: 392px !important;
  margin-top: 100px;
}
.height-7,
.size-7 {
  height: 392px !important;
}
.logo-box-primary .logo {
  color: #fff;
  background-color: #009688 !important;
}
.logo-box,
.logo-box .logo {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-content: center;
  -moz-align-content: center;
  -ms-align-content: center;
  align-content: center;
}
.logo-box .logo {
  width: 96px;
  height: 96px;
  font-size: 72px;
  font-weight: 500;
  color: #fff;
  background-color: #777e7d;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  align-content: center;
}
