.step {
  width: auto;
}
.step-text {
  display: none;
  flex-grow: 1;
  justify-content: center;
  // font-size: 1rem;
}
.step-bullet {
  display: flex;
  flex-grow: 1;
  position: relative;
  min-height: 4rem;
}

.step-number-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-number {
  height: 3rem;
  width: 3rem;
  background: #dadad9;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.step-number-active {
  transform: scale(1.05);
  background: #fb6340;
}
.step-number-complete {
  background: #5dd7a0;
}
.divider-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.step-divider {
  height: 0.2rem;
  background: rgba($color: #a5a0a0, $alpha: 0.5);
  display: flex;
  width: 44%;
}

@media (min-width: 250px) {
  .step-text {
    display: flex;
  }
}
