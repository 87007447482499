@import-normalize;
::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem;
}
::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
  border-width: 1px;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(165, 40, 31, 0.4);
  background-clip: padding-box;
  border: solid transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(165, 40, 31, 0.7);
}
