.accordion-header {
  border-radius: 10px;
  height: 2.5rem;
  display: flex;
  justify-content: flex-end;
}
.header-icon {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: none;
  z-index: 1;
  &:hover {
    background: rgba($color: #aca8a8, $alpha: 0.1);
  }
  &:focus {
    transform: scale(0.9);
    transition: 0.2 transform ease-in-out;
    background: rgba($color: #aca8a8, $alpha: 0.8);
  }
}
