.stepper {
  .step:first-child.step-divider {
    width: 50%;
  }
}

// @media (min-width: 150px) {
//   // .stepper {
//   //   transform: scale(0.55);
//   // }
// }
@media (max-width: 450px) {
  .step-number {
    height: 2.5em !important;
    width: 2.5rem !important;
    font-size: 1rem !important;
  }
}
@media (max-width: 350px) {
  .step-number {
    height: 2rem !important;
    width: 2rem !important;
    font-size: unset !important;
  }
  .step-text {
    font-size: 0.75rem;
  }
  // .stepper {
  //   transform: scale(0.6);
  // }
}
@media (max-width: 500px) {
  // .stepper {
  //   transform: scale(0.79);
  // }
}
@media (max-width: 700px) {
  // .stepper {
  //   transform: scale(0.85);
  // }
}
@media (max-width: 1200px) {
  // .stepper {
  //   transform: scale(1);
  // }
}
