// .css-2b097c-container {
//   padding: 0px;
// }

// .css-yk16xz-control {
//   background-color: #ffffff00 !important;
//   border-style: none !important;
// }

.css-1pahdxg-control {
  border-color: #e3e8ee !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
