.calendar-tabs {
  display: flex;
  flex-wrap: wrap;
  margin-right: 2rem;
  .nav-pills .nav-link:hover {
    &hover {
      color: white !important;
    }
  }
}
.calendar-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  flex-grow: 1;
  height: 30px;
  &:hover {
    // color: white !important;
    opacity: 0.9;
    cursor: pointer;
  }
}

.DatePicker__input {
  font-size: 1rem !important;
}
.responsive-calendar {
  /* by setting font-size, all the elements will correspond */
  font-size: 0.6rem !important; /* default to 10px */
}

@media (max-width: 1500px) {
  .responsive-calendar {
    font-size: 0.6rem !important;
  }
}

@media (max-width: 1200px) {
  .responsive-calendar {
    font-size: 0.6rem !important;
  }
}

@media (max-width: 768px) {
  .responsive-calendar {
    font-size: 0.5rem !important;
  }
}

/* Large screens */
@media (min-width: 2500px) {
  .responsive-calendar {
    font-size: 1rem !important;
  }
}
