.history-card::after {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  /* Transition to showing the bigger shadow on hover */
  .history-card:hover {
      cursor: pointer;
    box-shadow: 0 0 16px rgba(33, 33, 33, 0.2);
  }
  
  .history-card {
    .footer-icon {
      margin-top: 2px;
    }
  }
  
  .footer-button {
      &:hover {
          opacity: 0.5;
      }
  }