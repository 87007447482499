.my-nav-bar {
  padding: unset !important;
}
.card {
  overflow: visible !important;
}
.my-navbar-tabs {
  background: #dadad9 !important;
  color: #36382e !important;
  width: 100% !important;
  .nav-item {
    flex-grow: 1 !important;
  }
  .my-tab {
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0 !important;
    font-size: 0.9em;
    font-weight: 600 !important;
    color: black !important;
    svg {
      margin-right: unset !important;
      margin-bottom: 2px !important;
    }
  }
  .my-tab:hover,
  .my-tab:focus {
    color: black !important;
  }
}

@media (max-width: 300px) {
  .my-tab {
    font-size: 0.4rem !important;
  }
  .tab-selected {
    font-size: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .my-container {
    padding-right: unset !important;
    padding-left: unset !important;
  }
}

@media (max-width: 700px) {
  .my-tab {
    font-size: 0.8rem !important;
  }
  .tab-selected {
    font-size: 0.9rem !important;
  }
}
@media (min-width: 768px) {
  .main-content .container-fluid {
    padding-left: unset !important;
    padding-right: unset !important;
  }
  .my-tab {
    font-size: 0.875rem !important;
  }
  .tab-selected {
    font-size: 0.95rem !important;
  }
}
@media (max-width: 500px) {
  .main-content .container-fluid {
    padding-left: unset !important;
    padding-right: unset !important;
  }
  .my-navbar-tabs .my-tab {
    font-size: 0.7rem !important;
  }
  .my-navbar-tabs .tab-selected {
    font-size: 0.775rem !important;
  }
}
.tab-selected {
  // background: #ede6e3 !important;
  color: #36382e !important;
  font-weight: bold !important;
  border: 1px solid #a8a8a8;
  border-top: unset !important;
  border-bottom: unset !important;
  background: #f1ebe8 !important;
  font-size: 1rem !important;
  opacity: 1;
}
.nav-item .tab-selected {
  font-size: 1.1rem;
}
